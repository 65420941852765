<script>

import { popularizeList } from "@/api/my";

export default {
    name:"KickbackList",
    components: {
        Header: () => import('components/base/Header'),

        'mt-header':app.Mint.Header,
        'mt-button':app.Mint.Button,
    },
    data() {
        return {
            list:[],

            header: {
                mainTitle: getWord(['commission', 'detail']),
            },
        }
    },
    methods: {
        //返回
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        },
    },
    mounted() {
        popularizeList().then(result=>{
            if(result.data.code === 'SUCCESS'){
                this.list = result.data.result.list;

                this.CustomScroll(this.$refs.conmission.id);
            }else{
                app.Mint.Toast(result.data.msg);
            }
        })
    }
};
</script>

<template>
    <div id="commission" ref="conmission" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <mt-header :title="getWord(['commission', 'detail'])" style="height:0.88rem;font-size: 0.35rem;">
                <mt-button icon="back" slot="left" @click="left_click()"></mt-button>
            </mt-header>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
        </template>
        <table border="0" cellpadding="0" cellspacing="0">
            <tr>
                <th>{{ getWord('date') }}</th>
                <th>{{ getWord('referral') }}</th>
                <th>{{ getWord(['earn', 'commission']) }}</th>
            </tr>
            <tr v-for='(item, index) in list' :key='index'>
                <td>{{new Date(item.created_at*1000).toLocaleDateString().replace(/\//g, "/")}}</td>
                <td>{{item.username}}</td>
                <td style="color:red;">{{Number(item.money).toFixed(2)}}</td>
            </tr>
        </table>
    </div>
</template>

<style lang="scss" scoped style="text/css">
#commission {
    overflow: auto;

    &.template-3 {

        table {

            th {
                font-size: .3rem;
                font-weight: normal;
            }
        }
    }
}
table{
    width:100%;
    text-align: center;
    font-size:0.28rem;

    tr{

        th{
            color: #999;
            padding: 0.3rem 0;
            background:#EAEAEA;
        }
        td{
            color: #666;
            padding: 0.2rem 0;
            border-bottom:1px solid #CCC;
        }
    }
}
</style>
